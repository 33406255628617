/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

* { margin: O; padding: 0; }
* ,*::before, *::after { box-sizing: border-box; }

body { min-height: 100dvh; }
input, button, textarea, select { font: inherit; }

p { text-wrap: pretty; }
h1, h2, h3, h4, h5, h6 { text-wrap: balance; }

img, video, svg { height: auto; max-width: 100%; }

@media (prefers-reduced-motion: reduce) {
  * , *::before, *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition: none;
  }
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: url('/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: url('/fonts/Poppins-Bold.ttf') format('truetype');
}

html {
  font-family: "Poppins", system-ui, sans-serif;
}

h1 {
  font-weight: 700;
  font-size: 2.8rem;
}

h2 {
  // font-weight: 700;
  font-size: 2rem;
}

h3 {
  font-weight: 700;
  font-size: 1.3rem;
}

p {
  font-size: 14px;
}

.scrollbar, body::-webkit-scrollbar {
  width: 0.7em;
}

.scrollbar, body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #fef1e5;
}

.scrollbar, body::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #FF7F50;
}

.scrollbar:hover, body:hover::-webkit-scrollbar-thumb {
  background-color: gainsboro;
}


// SCROLLBAR SECUNDARY
.scrollbar-sec::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

.scrollbar-sec::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 100vh;
  border: 3px solid #f6f7ed;
}

.scrollbar-sec::-webkit-scrollbar-thumb:hover {
  background: grey;
}